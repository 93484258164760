import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {  

  constructor(private storage: DataService) {
    console.log('Hello AuthorizationProvider Provider');
  }

  public async setPhone(phone: string): Promise<void> {
    return this.storage.set('phone', phone);
  }

  public async getPhone(): Promise<string> {
    return Promise.resolve(this.storage.get('phone'));
    //TODO remove comment
    //return '+33752666206';
  }

  public async setToken(accessToken: string | null): Promise<void> {
    console.log(accessToken);    
    return this.storage.set('token', accessToken);
  }

  

  public async getToken(): Promise<string> {
    return Promise.resolve(this.storage.get('token'));
    //return '16932176910rchsy3sYgPcDn3pTMUCXbfPXII8A1f7pX9Jo6y0iJeupkJuAX1CcqMF9r7ZPN2PU7wndFfenyzUWkd6P8C1zzZRY6f2r7WI4kKpyzmQnEhedB6LGCaXM9Z6nsWqi';
  }

  public isAuthenticated(): boolean {
    //NICE (note used)
    // get the token
    const token = this.getToken();
    // return a boolean reflecting 
    // whether or not the token is expired
    return false;//tokenNotExpired(token);
  }

  public logout(): void {
    this.storage.remove('token');
    this.storage.remove('phone');
  }

  // public getUploadHeaders(fileKey, fileName): Promise<FileUploadOptions> {
  //   return this.getToken().then(
  //     (token) => {
  //       let options: FileUploadOptions = {
  //         fileKey: fileKey,
  //         fileName: fileName,
  //         headers: {
  //           'X-Auth-Token': '',
  //           'Mbn-Header': token,
  //           "Cookie": token
  //         }
  //       }
  //       return options;
  //     }
  //   );
  // }
}
