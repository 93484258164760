import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./login/login.page').then( m => m.LoginPage)
  },
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.routes').then((m) => m.routes),
  },   {
    path: 'menu',
    loadComponent: () => import('./menu/menu.page').then( m => m.MenuPage)
  },
  {
    path: 'account',
    loadComponent: () => import('./account/account.page').then( m => m.AccountPage)
  },
  {
    path: 'club-events',
    loadComponent: () => import('./club-events/club-events.page').then( m => m.ClubEventsPage)
  },
  {
    path: 'club-participations',
    loadComponent: () => import('./club-participations/club-participations.page').then( m => m.ClubParticipationsPage)
  },
  {
    path: 'sex-filter',
    loadComponent: () => import('./sex-filter/sex-filter.page').then( m => m.SexFilterPage)
  },
  {
    path: 'conversation',
    loadComponent: () => import('./conversation/conversation.page').then( m => m.ConversationPage)
  },
  {
    path: 'conversation-list',
    loadComponent: () => import('./conversation-list/conversation-list.page').then( m => m.ConversationListPage)
  },
  {
    path: 'user',
    loadComponent: () => import('./user/user.page').then( m => m.UserPage)
  },
  {
    path: 'user-calendar',
    loadComponent: () => import('./user-calendar/user-calendar.page').then( m => m.UserCalendarPage)
  },
  {
    path: 'imhere',
    loadComponent: () => import('./imhere/imhere.page').then( m => m.ImherePage)
  },
  {
    path: 'notification',
    loadComponent: () => import('./notification/notification.page').then( m => m.NotificationPage)
  },
  {
    path: 'follow',
    loadComponent: () => import('./follow/follow.page').then( m => m.FollowPage)
  },
  {
    path: 'notification-setting',
    loadComponent: () => import('./notification-setting/notification-setting.page').then( m => m.NotificationSettingPage)
  },
  {
    path: 'debug',
    loadComponent: () => import('./debug/debug.page').then( m => m.DebugPage)
  },
 
];
