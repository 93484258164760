import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Notification } from '../model/notification/notification';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private _storage: Storage | null = null;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
  }

  // Create and expose methods that users of this service can
  // call, for example:
  public set(key: string, value: any) {
    return this._storage?.set(key, value);
  }

  public async get(key: string) {
    return this._storage?.get(key);
  }  

  public async remove(key: string) {
    return this._storage?.remove(key);
  }  

  // public getPreference(preference: string): Promise<any> | undefined {
  //   return this._storage?.get(preference);
  // }

  // public savePreference(preference: string, value: any): Promise<any> | undefined {
  //   return this._storage?.set(preference, value);
  // }

  public getNotifications(): Promise<Notification[]> | undefined {
    let notifications: Notification[] = [];
    return this._storage?.forEach(
      (val: string, key: string) => {
        let regexp = /^[0-9]+-[0-9]+$/;
        let regexp2 = /^FCM.*/;
        if (regexp.test(key) || regexp2.test(key)) {
          //LoggerStatic.log(val);
          notifications.push(JSON.parse(val));
        }
      }
    ).then(
      () => {
        return notifications;
      }
    )
  }

  public deleteAllNotifcations(): Promise<void> | undefined {
    return this.getNotifications()?.then(
      (notifications) => {
        notifications.forEach(
          (notification) => {
            this.deleteNotification(notification);
          }
        )
      }
    );
  }

  public getNotification(idtc: string): Promise<Notification> | undefined {
    return this._storage?.get(idtc).then(
      (val: string) => {
        if (val) {
          return JSON.parse(val);
        } else {
          return null;
        }
      }
    );
  }

  public saveNotification(notification: Notification): Promise<any> | undefined {
    return this._storage?.set(notification.idtc, JSON.stringify(notification));
  }

  public deleteNotification(notification: Notification): Promise<any> | undefined {
    return this._storage?.remove('' + notification.idtc);
  }

}
