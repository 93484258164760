import { environment } from "src/environments/environment";

export class Configuration {
    //public static apiUrl: string = 'https://api.meetbynight.com/directus/middle';
    public static apiUrl: string = environment.apiUrl;
    public static socketUrl: string = 'https://api.meetbynight.com:2100';
    public static cdnUrl: string = 'https://api.meetbynight.com/directus/storage/uploads/';
    //public static cdnUrl: string = 'http://localhost/assets/';

    //radius to edge of geofence in meters
    public static geofenceClubRadius: number = 200;

    public static uber_api_url = "https://api.uber.com/v1.2";
    public static uber_estimate_price_url = Configuration.uber_api_url + "/estimates/price";

    public static uber_server_token = "_R9SDV29ZEqITBUD7mmpcVT0AbU15acTORAx3y2O";
}