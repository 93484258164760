import { Injectable } from '@angular/core';
import { AuthorizationService } from './authorization.service';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Configuration } from '../model/configuration';

/**
 * Ajout du token dans toute les requêtes.
 */
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(public auth: AuthorizationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.lastIndexOf("uber") > -1) {
      request = request.clone({
        setHeaders: {
          'Authorization': 'Token ' + Configuration.uber_server_token,
          "Accept-Language": 'fr_FR',
          'Content-Type': 'application/json'
        }
      });
      return next.handle(request);
    }
    console.log("intercept");
    return from(this.auth.getToken()).pipe(
      switchMap(
        (token) => {
          console.log("intercept - setheaders", token);
          if (token) {
            request = request.clone({
              setHeaders: {
                'Mbn-Header': token,
                "Cookie": token,
                //'Content-Type': 'text/plain;charset=UTF-8'
              }
            });
          }
          return next.handle(request);
        }
      )
    );
  }
}
